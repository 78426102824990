import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import {
  Box,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Link,
  Divider,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { Item } from "react-grid-carousel";
import { useNavigate } from "react-router-dom";
const Layout = () => {
  const navigate = useNavigate();
  const actions = [
    {
      icon: <WhatsAppIcon />,
      name: "Whatsapp",
      url: "https://wa.me/+009613999674",
    },
    { icon: <ContactPageIcon />, name: "Contact page" },
  ];

  const redirect = (action) => {
    console.log(action);
    if (action.name === "Whatsapp") {
      window.location.href = action.url;
    } else {
      navigate("/contactus");
    }
  };
  return (
    <>
      <Navbar />
      <Outlet />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          ".MuiSpeedDial-fab": {
            background: "#001a72",
            "&:focus": { background: "#001a72" },
          },
        }}
        icon={<AddIcCallIcon />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              redirect(action);
            }}
          />
        ))}
      </SpeedDial>
      <Footer />
    </>
  );
};

export default Layout;
