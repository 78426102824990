import contactusBg from "../../assets/images/contactus.svg";
import contactusBottom from "../../assets/images/contactusbottom.svg";
import testbg from "../../assets/images/contactus.jpg";
import contactusLottie from "../../assets/images/contactus1.gif";
import Ycard from "../../components/cards/Ycard";
import bgFooter from "../../assets/images/wave-footer.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  Stack,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../../components/Sections/Header";
import line from "../../assets/images/line-1.svg";

const Contactus = () => {
  const breadcrumbs = [
    <Link to="/" key="1" style={{ color: "white" }}>
      Home
    </Link>,
    <Typography key="2" color="white">
      Contact us
    </Typography>,
  ];
  return (
    <>
      <Header
        title="Contact us"
        breadcrumbs={breadcrumbs}
        description="We'd love to hear from you"
      />
      <Box my={5}>
        <Container>
          <Card
            sx={{ borderRadius: "10px", backgroundColor: "rgba(0,36,136,1)" }}
            elevation={0}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CardMedia
                    image={testbg}
                    component="img"
                    sx={{ borderRadius: "5px", height: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={2}
                  sx={{
                    background: `url(${line})`,
                    backgroundPosition: "bottom",
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  <Box my={2} />

                  <Ycard
                    title="Let's talk about your water"
                    text="00961 3 999674"
                  />
                  <Box my={2} />
                  <Ycard
                    title="Book a service with a water professional"
                    text="00961 4 811674"
                  />
                  <Box my={2} />

                  <Ycard title="Help Desk" text=" 00961 3 999674" />
                  <Box my={2} />

                  <Stack direction="row" pt={1} spacing={1}>
                    {/* <IconButton aria-label="Instagram account">
                      <InstagramIcon
                        sx={{ color: "white", fontSize: "20px" }}
                      />
                    </IconButton>
                   
                    <IconButton aria-label="Facebook account">
                      <WhatsAppIcon sx={{ color: "white", fontSize: "20px" }} />
                    </IconButton>
                    <IconButton aria-label="LinkedIn account">
                      <LinkedInIcon sx={{ color: "white", fontSize: "20px" }} />
                    </IconButton> */}
                    <a href="https://wa.me/+009613999674">
                      <WhatsAppIcon sx={{ color: "white", fontSize: "20px" }} />
                    </a>
                    <a href="mailto:info@ch2olb.com">
                      <MailOutlineIcon
                        sx={{ color: "white", fontSize: "20px" }}
                      />
                    </a>
                  </Stack>
                  <Box my={2} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      {/* <img src={contactusBg} />
      <Box sx={{ marginTop: -1, py: 5 }}>
        <Container>
          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={8}>
              <Card
                sx={{ height: "250px", borderRadius: "10px" }}
                elevation={0}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <CardMedia
                        image={testbg}
                        component="img"
                        sx={{ height: "250px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Typography variant="h4" py={1}>
                        Let's talk about your water
                      </Typography>
                      <Typography variant="h6" py={2}>
                        00961 3 999674
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "250px",
                  borderRadius: "10px",
                  my: 5,
                  "&:hover": {
                    backgroundColor: "#f3f4f5",
                    border: "1px solid white",
                  },
                }}
                elevation={0}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <CardMedia
                        image={testbg}
                        component="img"
                        sx={{ height: "250px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Typography variant="h4" py={1} textAlign="center">
                        Book a service with a water professional
                      </Typography>
                      <Typography variant="h6" py={2}>
                        00961 3 999674
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <Card
                sx={{ height: "250px", borderRadius: "10px" }}
                elevation={0}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <CardMedia
                        image={testbg}
                        component="img"
                        sx={{ height: "250px" }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      direction="column"
                    >
                      <Typography variant="h4" py={1}>
                        Help Desk
                      </Typography>
                      <Typography variant="h6" py={2}>
                        00961 3 999674
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <img src={contactusBottom} /> */}
    </>
  );
};
export default Contactus;
