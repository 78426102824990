import { Box, Container } from "@mui/system";
import { Grid, Typography, Stack, Divider, IconButton } from "@mui/material";
import bgFooter from "../../assets/images/wave-footer.svg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ch2oLogo from "../../assets/images/logo.png";
const Footer = () => {
  return (
    <Box
      sx={{
        background: "rgb(90,126,255)",
        background:"linear-gradient(130deg, rgba(90,126,255,1) 0%, rgba(0,36,136,1) 100%)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
        <Typography color="white" variant="caption" textAlign="center" py={5}>
          © Copyright 2022 CH2OLB. All Rights Reserved.
          <br />
          POWERED BY sss
        </Typography>
    </Box>
  );
};

export default Footer;
